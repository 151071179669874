<template>
	<App title="Admins">
		<Block>
			<template #header>
				<h3 class="block-title">Admins</h3>
			</template>
			<Datatable :options="options" />
		</Block>
	</App>
</template>

<script lang="ts">
import Block from '@/vue/components/Block.vue'
import { CellRender, Column } from '@/vue/components/Datatable'
import Datatable from '@/vue/components/Datatable.vue'
import App from '@/vue/templates/App.vue'
import { Component, toNative, Vue } from 'vue-facing-decorator'

@Component({
	components: {
		App,
		Block,
		Datatable,
	},
})
class Group extends Vue {
	get buttons() {
		let buttons: any[] = []
		buttons.push('refresh')
		buttons.push({
			extend: 'create',
			attr: {
				href: this.route('admin.page.create'),
			},
		})
		buttons.push('trashed')

		return buttons
	}

	get columns() {
		let columns: any[] = []
		columns.push({
			data: 'id',
			orderable: true,
			name: 'id',
			title: 'Id',
		})

		columns.push({
			data: 'fullname',
			title: 'Name',
		})
		columns.push({
			data: 'email',
			title: 'email',
			orderable: true,
			name: 'title',
		})
		columns.push({
			data: 'phone',
			title: 'Phone',
			orderable: true,
			name: 'phone',
		})

		columns.push({
			data: 'role',
			title: 'Role',
			orderable: true,
			name: 'role',
		})

		columns.push({
			data: 'created_at',
			title: 'Registered',
			render: CellRender.date(),
			orderable: true,
			name: 'created_at',
		})

		columns.push(Column.actions(this.$gate))

		return columns
	}

	get options() {
		return {
			ajax: {
				url: this.route('admin.ajax.table'),
				method: 'POST',
			},
			buttons: this.buttons,
			columns: this.columns,
		}
	}
}
export default toNative(Group)
</script>
