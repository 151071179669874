<template>
	<App title="Admin Profile">
		<div class="row">
			<div class="col">
				<Block type="data">
					<template #header>
						<h3 class="block-title">Admin Profile Information</h3>
					</template>

					<div class="row">
						<div class="col-12">
							<TextBlock label="Fullname">
								{{ admin.fullname }}
							</TextBlock>
						</div>
					</div>
					<div class="row">
						<div class="col-6">
							<TextBlock label="Email">
								{{ admin.email }}
							</TextBlock>
						</div>
						<div class="col-6">
							<TextBlock label="Phone">
								{{ admin.phone }}
							</TextBlock>
						</div>
					</div>
					<div class="row">
						<div class="col-12">
							<TextBlock label="Access Level">
								{{ admin.role }}
							</TextBlock>
						</div>
					</div>
				</Block>
			</div>
		</div>
	</App>
</template>
<script lang="ts">
import type Admin from '@/models/Admin'
import Block from '@/vue/components/Block.vue'
import TextBlock from '@/vue/components/TextBlock.vue'
import App from '@/vue/templates/App.vue'
import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

@Component({
	components: {
		App,
		Block,
		TextBlock,
	},
})
class Single extends Vue {
	@Prop({ type: String })
	readonly admin!: Admin
}

export default toNative(Single)
</script>
