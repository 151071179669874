<template>
	<Auth title="Login">
		<template #heading>
			<h1 id="title" class="fw-bold mb-2">Log In</h1>
		</template>
		<Alert ref="alert" class="alert-danger">
			{{ message }}
		</Alert>
		<Form ref="form" @submit="onSubmit">
			<InputField id="email" v-model="email" name="email" rules="required|email" label="Email" placeholder="Email" />
			<InputField id="password" v-model="password" name="password" rules="required" label="Password" type="password" placeholder="password" />
			<div class="d-flex justify-content-between align-items-center mb-4">
				<div>
					<Link :href="route('auth.page.password.recover')" class="text-muted fs-sm fw-medium d-block d-lg-inline-block mb-1"> Forgot Password? </Link>
				</div>
				<div>
					<Button id="action" type="submit" :label="labels" :loading="loading" :done="done" class="btn-lg btn-alt-primary" />
				</div>
			</div>
		</Form>
	</Auth>
</template>

<script lang="ts">
import { error } from '@/ts/app'
import Alert from '@/vue/components/Alert.vue'
import { ButtonLabel } from '@/vue/components/Button'
import Button from '@/vue/components/Button.vue'
import Field from '@/vue/components/Field.vue'
import Form from '@/vue/components/Form.vue'
import InputField from '@/vue/components/InputField.vue'
import Link from '@/vue/components/Link.vue'
import Auth from '@/vue/templates/Auth.vue'
import { AxiosError } from 'axios'
import { isNull, isUndefined } from 'lodash'
import { Component, Ref, Vue, Watch, toNative } from 'vue-facing-decorator'

@Component({
	components: {
		Auth,
		Field,
		InputField,
		Form,
		Button,
		Alert,
		Link,
	},
})
class Login extends Vue {
	declare url?: {
		intended: string
	}

	message = ''

	email = ''
	password = ''

	loading = false
	done = false

	@Ref('form')
	readonly form!: InstanceType<typeof Form>

	@Ref('alert')
	readonly alert!: InstanceType<typeof Alert>

	@Watch('message')
	onMessageChanged(value: string) {
		if (value.length > 0) {
			this.alert.show()
		} else {
			this.alert.hide()
		}
	}

	get labels(): ButtonLabel {
		return {
			normal: 'Login',
			loading: 'Logging In',
			done: 'Logged In',
		} as ButtonLabel
	}

	get intended(): string {
		if (isNull(this.url) || isUndefined(this.url)) {
			return this.route('dashboard.page')
		}
		return this.url?.intended
	}

	onSubmit(payload) {
		this.loading = true
		this.message = ''
		this.$http
			.post(this.route('login'), payload)
			.then(() => {
				this.router.visit(this.intended, { replace: true })
				this.done = true
			})
			.catch((e: AxiosError) => error(e, this))
			.finally(() => (this.loading = false))
	}
}

export default toNative(Login)
</script>
