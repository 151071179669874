<template>
	<App title="Update Partner">
		<template #top>
			<Alert ref="alert" class="alert-danger">
				{{ message }}
			</Alert>
		</template>
		<Block type="form">
			<template #header>
				<h3 class="block-title">Update Partner</h3>
			</template>
			<div class="row">
				<div class="col-12">
					<Form ref="form" @submit="onSubmit">
						<div class="row g-3">
							<div class="col-12">
								<InputField id="name" v-model="name" name="name" rules="required" label="Name" placeholder="Name" />
							</div>
							<div class="col-12">
								<InputField id="email" v-model="email" name="email" rules="required|email" label="Email" type="email" placeholder="Email" />
							</div>
							<div class="col-12">
								<PhoneField id="phone" v-model="phone" name="phone" rules="required|phone" label="Phone" placeholder="Phone" />
							</div>
							<div class="col-12">
								<TextareaField id="address" v-model="address" name="address" rules="required" label="Address" placeholder="Address" />
							</div>
							<div class="col-12">
								<InputField id="webhook" v-model="webhook" name="webhook" rules="url" label="Webhook" type="url" placeholder="webhook" />
							</div>
							<!--  <div class="col-12">
                                <InputField id="password" v-model="password" type="password" name="password" rules="required" label="Password" placeholder="Password" />
                            </div>
                            <div class="col-12">
                                <InputField id="password_confirmation" v-model="password_confirmation" type="password" name="password_confirmation" rules="required|confirmed:@password" label="Password Confirmation" placeholder="Password Confirmation" />
                            </div> -->
							<div class="d-grid gap-2 d-md-flex justify-content-md-end">
								<button class="btn btn-alt-secondary me-1 mb-3">Cancel</button>
								<Button id="action" type="submit" :label="labels" :loading="loading" :done="done" class="btn btn-alt-primary me-1 mb-3" />
							</div>
						</div>
					</Form>
				</div>
			</div>
		</Block>
	</App>
</template>

<script lang="ts">
import type Partner from '@/models/Partner'
import { error } from '@/ts/app'
import Alert from '@/vue/components/Alert.vue'
import Block from '@/vue/components/Block.vue'
import { ButtonLabel } from '@/vue/components/Button'
import Button from '@/vue/components/Button.vue'
import Form from '@/vue/components/Form.vue'
import InputField from '@/vue/components/InputField.vue'
import PhoneField from '@/vue/components/PhoneField.vue'
import TextareaField from '@/vue/components/TextareaField.vue'
import App from '@/vue/templates/App.vue'
import { AxiosError } from 'axios'
import { Component, Prop, Ref, Vue, Watch, toNative } from 'vue-facing-decorator'

@Component({
	components: {
		App,
		Form,
		Alert,
		InputField,
		PhoneField,
		Button,
		Block,
		TextareaField,
	},
})
class Update extends Vue {
	message = ''

	name = ''
	email = ''
	phone = ''
	address = ''
	webhook = ''
	//password = ''
	//password_confirmation = ''

	loading = false
	done = false

	@Ref('form')
	readonly form!: InstanceType<typeof Form>

	@Ref('alert')
	readonly alert!: InstanceType<typeof Alert>

	@Prop({ type: Object })
	readonly partner!: Partner

	@Watch('message')
	onMessageChanged(value: string) {
		if (value.length > 0) {
			this.alert.show()
		} else {
			this.alert.hide()
		}
	}

	get labels(): ButtonLabel {
		return {
			normal: 'Update Partner',
			loading: 'Updating....Partner',
			done: 'Updated Partner',
		}
	}

	created() {
		this.name = this.partner.name
		this.email = this.partner.email
		this.phone = this.partner.phone
		this.address = this.partner.address
		this.webhook = this.webhook
	}

	async onSubmit(payload) {
		this.loading = true
		this.$http
			.post(this.route('partner.ajax.update'), payload)
			.then((response) => {
				this.done = true
				this.router.visit((response.data.partner as Partner).url.single)
			})
			.catch((e: AxiosError) => error(e, this))
			.finally(() => (this.loading = false))
	}
}

export default toNative(Update)
</script>
