<template>
	<App title="Partner">
		<div class="row">
			<div class="col">
				<Block type="data">
					<template #header>
						<h3 class="block-title">Partner Information</h3>
					</template>

					<div class="row">
						<div class="col-12">
							<TextBlock label="Name">
								{{ partner.name }}
							</TextBlock>
						</div>
					</div>
					<div class="row">
						<div class="col-6">
							<TextBlock label="Email">
								{{ partner.email }}
							</TextBlock>
						</div>
						<div class="col-6">
							<TextBlock label="Phone">
								{{ partner.phone }}
							</TextBlock>
						</div>
					</div>
					<div class="row">
						<div class="col-12">
							<TextBlock label="Address">
								{{ partner.address }}
							</TextBlock>
						</div>
					</div>
					<div class="row">
						<div class="col-6">
							<TextBlock label="Client ID">
								{{ partner.keys.client_id }}
							</TextBlock>
						</div>
						<div class="col-6">
							<TextBlock label="Client Secret">
								{{ partner.keys.client_secret }}
							</TextBlock>
						</div>
					</div>
					<div class="row">
						<div class="col-12">
							<TextBlock label="Webhook URL">
								{{ partner.webhook }}
							</TextBlock>
						</div>
					</div>
				</Block>
			</div>
		</div>
		<UserTable :partner />
	</App>
</template>
<script lang="ts">
import type Partner from '@/models/Partner'
import Block from '@/vue/components/Block.vue'
import TextBlock from '@/vue/components/TextBlock.vue'
import App from '@/vue/templates/App.vue'
import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'
import UserTable from './snippets/UserTable.vue'

@Component({
	components: {
		App,
		Block,
		TextBlock,
		UserTable,
	},
})
class Single extends Vue {
	@Prop({ type: Object })
	readonly partner!: Partner
}

export default toNative(Single)
</script>
