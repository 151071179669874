<template>
	<App title="Disbursements">
		<Block>
			<template #header>
				<h3 class="block-title">Disbursements</h3>
			</template>
			<Datatable ref="datatable" :options="options" />
		</Block>
	</App>
</template>
<script lang="ts">
import { error } from '@/ts/app'
import Block from '@/vue/components/Block.vue'
import { CellRender, Column } from '@/vue/components/Datatable'
import Datatable from '@/vue/components/Datatable.vue'
import App from '@/vue/templates/App.vue'
import Swal from 'sweetalert2'
import { Component, Ref, Vue, toNative } from 'vue-facing-decorator'

@Component({
	components: {
		App,
		Block,
		Datatable,
	},
})
class Group extends Vue {
	get buttons() {
		let buttons: any[] = []
		buttons.push('refresh')
		buttons.push('trashed')

		return buttons
	}

	get columns() {
		let columns: any[] = []
		columns.push({
			data: 'id',
			orderable: true,
			name: 'id',
			title: 'Id',
		})

		columns.push({
			data: 'user.fullname',
			title: 'User',
		})
		columns.push({
			data: 'amount',
			title: 'Amount',
			name: 'amount',
			render: CellRender.money(),
			orderable: true,
		})

		columns.push({
			data: 'disbursible_type',
			title: 'Type',
			name: 'disbursible_type',
			orderable: true,
			render: (data: string) => data.replace('App\\Models\\', ''),
		})

		columns.push({
			data: 'destination',
			title: 'Destination',
			name: 'destination',
			orderable: true,
			class: 'text-capitalize',
		})

		columns.push({
			data: 'created_at',
			title: 'Requested',
			name: 'created_at',
			render: CellRender.date(),
			orderable: true,
		})
		columns.push({
			data: 'approved_at',
			title: 'Approved',
			name: 'approved_at',
			render: CellRender.date(),
			orderable: true,
		})
		columns.push({
			data: 'disbursed_at',
			title: 'Disbursed',
			name: 'disbursed_at',
			render: CellRender.date(),
			orderable: true,
		})

		if (this.$gate.allow('approve', 'disbursement')) {
			columns.push({
				title: 'Approval',
				data: 'url',
				class: 'text-center',
				render: (_data, _type, row) => (row.approved_at ? '' : `<button class="btn btn-success approval" data-id="${row.id}"><i class="fa fa-check"></i></button>`),
			})
		}

		columns.push(Column.actions(this.$gate, { update: false, view: false }))

		return columns
	}

	get options() {
		return {
			ajax: {
				url: this.route('disbursement.ajax.table'),
				method: 'POST',
			},
			buttons: this.buttons,
			columns: this.columns,
			order: [[5, 'desc']],
			select: false,
		}
	}

	@Ref('datatable')
	datatable!: any

	mounted() {
		this.datatable.datatable.on('click', '.approval', (e: any) => {
			e.preventDefault()

			let button = e.currentTarget as HTMLButtonElement
			let disbursement = button.getAttribute('data-id')
			button.disabled = true
			button.innerHTML = '<div class="spinner-border text-light" role="status"><span class="sr-only">Loading...</span></div>'
			Swal.fire({
				title: 'Are You Sure?',
				text: "You won't be able to revert this!",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'YES, APPROVE IT',
			}).then((result) => {
				if (result.isConfirmed) {
					this.$http
						.patch(this.route('disbursement.ajax.approval', { disbursement }))
						.then(() => this.datatable.datatable.draw())
						.catch((e: any) => error(e))
						.finally(() => (button.disabled = false))
				}
				button.innerHTML = '<i class="fa fa-check"></i>'
				button.disabled = false
			})
		})
	}
}
export default toNative(Group)
</script>
