<template>
	<App title="Easy Stacs">
		<div class="row">
			<div class="col">
				<Block type="data">
					<template #header>
						<h3 class="block-title">Easy Stacs Details</h3>
					</template>
					<div class="row">
						<div class="col-8">
							<TextBlock label="Title">
								{{ easy.title }}
							</TextBlock>
						</div>
						<div class="col-4">
							<TextBlock label="User">
								<a class="text-accent1" :href="easy.user?.url.single">{{ easy.user?.fullname }}</a>
							</TextBlock>
						</div>
					</div>
					<div class="row">
						<div class="col-4">
							<TextBlock label="Amount">
								{{ easy.amount.formatted }}
							</TextBlock>
						</div>
						<div class="col-4">
							<TextBlock label="Income">
								{{ easy.income.formatted }}
							</TextBlock>
						</div>
						<div class="col-4">
							<TextBlock label="Interest Rate">
								{{ easy.interest.data }}
							</TextBlock>
						</div>
					</div>
					<div class="row">
						<div class="col-4">
							<TextBlock label="Balance">
								{{ easy.balance.formatted }}
							</TextBlock>
						</div>
						<div class="col-4">
							<TextBlock label="Earned">
								{{ easy.earned.formatted }}
							</TextBlock>
						</div>
					</div>
					<div class="row">
						<div class="col-4">
							<TextBlock label="Created Date">
								{{ $render.date(easy.created_at) }}
							</TextBlock>
						</div>
						<div class="col-4">
							<TextBlock label="Maturity Date">
								{{ $render.date(easy.yielded_at) }}
							</TextBlock>
						</div>
						<div class="col-4">
							<TextBlock label="Completed Date">
								{{ $render.date(easy?.completed_at) }}
							</TextBlock>
						</div>
					</div>
					<div class="row">
						<div class="col-4">
							<TextBlock label="Withdrawn">
								{{ $render.bool(easy.hasWithdrawn) }}
							</TextBlock>
						</div>
						<div class="col-4">
							<TextBlock label="Extended">
								{{ $render.bool(easy.isExtended) }}
							</TextBlock>
						</div>
						<div class="col-4">
							<TextBlock label="Locked">
								{{ $render.bool(easy.isLocked) }}
							</TextBlock>
						</div>
					</div>
					<div v-if="easy.isLocked" class="row">
						<div class="col-8">
							<TextBlock label="Lock Remark">
								{{ easy.locked_remark }}
							</TextBlock>
						</div>
						<div class="col-4">
							<TextBlock label="Locked date">
								{{ $render.date(easy.locked_at) }}
							</TextBlock>
						</div>
					</div>
					<template #footer>
						<button v-if="!easy.isCompleted" class="btn btn-tertiary text-danger" @click="onComplete">Complete Plan</button>
						<button v-if="easy.isExtendable" class="btn btn-tertiary text-warning" @click="onExtend">Extend Plan</button>
						<button v-if="easy.isLocked" class="btn btn-tertiary text-success" @click="onLock($event, 'unlock')">Unlock Plan</button>
						<button v-else class="btn btn-tertiary text-danger" @click="onLock($event, 'lock')">Lock Plan</button>
					</template>
				</Block>
			</div>
		</div>

		<TransactionTable :model="easy" />
		<LockerBox ref="locker-modal" :plan="easy" />
	</App>
</template>
<script lang="ts">
import type Easy from '@/models/Easy'
import LockerBox from '@/pages/snippets/LockerBox.vue'
import TransactionTable from '@/pages/snippets/TransactionTable.vue'
import Block from '@/vue/components/Block.vue'
import Card from '@/vue/components/Card.vue'
import TextBlock from '@/vue/components/TextBlock.vue'
import App from '@/vue/templates/App.vue'
import { AxiosError } from 'axios'
import { Component, Prop, Ref, toNative, Vue } from 'vue-facing-decorator'

@Component({
	components: {
		App,
		Card,
		Block,
		TextBlock,
		TransactionTable,
		LockerBox,
	},
})
class Single extends Vue {
	@Prop({ type: Object })
	readonly easy!: Easy

	@Ref('locker-modal')
	readonly locker!: InstanceType<typeof LockerBox>

	onComplete(e: Event) {
		e.preventDefault()
		const button = e.target as HTMLButtonElement
		button.disabled = true
		const label = button.innerHTML
		button.innerHTML = '<i class="fas fa-spinner fa-spin"></i>'

		this.$sweet
			.fire({
				title: 'Are You Sure?',
				text: "You won't be able to revert this!",
				icon: 'warning',

				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'YES',
			})
			.then((result) => {
				if (result.isConfirmed) {
					this.$http
						.patch(this.route('easy.ajax.complete', { easy: this.easy.slug }))
						.then(() => this.router.reload({ only: ['easy'] }))
						.catch((e: AxiosError) => (this.$error(e), (button.disabled = false), (button.innerHTML = label)))
				} else {
					button.disabled = false
					button.innerHTML = label
				}
			})
	}
	onExtend(e: Event) {
		e.preventDefault()
		const button = e.target as HTMLButtonElement
		button.disabled = true
		const label = button.innerHTML
		button.innerHTML = '<i class="fas fa-spinner fa-spin"></i>'

		this.$sweet
			.fire({
				title: 'Are You Sure?',
				text: "You won't be able to revert this!",
				icon: 'warning',

				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'YES',
			})
			.then((result) => {
				if (result.isConfirmed) {
					this.$http
						.patch(this.route('easy.ajax.extend', { easy: this.easy.slug }))
						.then(() => this.router.reload({ only: ['easy'] }))
						.catch((e: AxiosError) => (this.$error(e), (button.disabled = false), (button.innerHTML = label)))
				} else {
					button.disabled = false
					button.innerHTML = label
				}
			})
	}

	onLock(e: Event, type: string) {
		e.preventDefault()
		const button = e.target as HTMLButtonElement
		button.disabled = true
		const label = button.innerHTML
		button.innerHTML = '<i class="fas fa-spinner fa-spin"></i>'
		console.log('here 0')
		if (type === 'unlock') {
			this.$sweet
				.fire({
					title: 'Are You Sure?',
					text: "You won't be able to revert this!",
					icon: 'warning',

					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'YES',
				})
				.then((result) => {
					console.log('Here 1')
					if (result.isConfirmed) {
						console.log('Here 2')
						this.$http
							.patch(this.route('easy.ajax.lock', { easy: this.easy.slug }), { type })
							.then(() => this.router.reload({ only: ['easy'] }))
							.catch((e: AxiosError) => (this.$error(e), (button.disabled = false), (button.innerHTML = label)))
					} else {
						button.disabled = false
						button.innerHTML = label
					}
				})
		} else {
			this.locker.modal.onToggleModal()
		}
	}
}
export default toNative(Single)
</script>
