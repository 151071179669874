<template>
	<App title="User Profile">
		<div class="row">
			<div class="col">
				<Block type="data">
					<template #header>
						<h3 class="block-title">User Information - #{{ user.id }}</h3>
					</template>
					<div class="row">
						<div class="col-8">
							<TextBlock label="Name">
								{{ user.fullname }}
							</TextBlock>
						</div>
						<div class="col">
							<TextBlock label="Partner">
								<Link :href="user.partner?.url.single ?? '#'">{{ user.partner?.name }}</Link>
							</TextBlock>
						</div>
					</div>
					<div class="row">
						<div class="col-6">
							<TextBlock label="Date Of Birth">
								{{ $render.date(user.birthday_at) }}
							</TextBlock>
						</div>
						<div class="col-6">
							<TextBlock label="Gender">
								{{ $render.enum(user.gender) }}
							</TextBlock>
						</div>
					</div>

					<div class="row">
						<div class="col">
							<TextBlock label="Email">
								{{ user.email }}
							</TextBlock>
						</div>
						<div class="col">
							<TextBlock label="Phone"> {{ user.phone }} ({{ $render.date(user.phone_verified_at) }}) </TextBlock>
						</div>
						<div class="col">
							<TextBlock label="Tag">
								{{ user?.tag }}
							</TextBlock>
						</div>
					</div>
					<div v-if="user.address" class="row">
						<div class="col">
							<TextBlock label="Address">
								{{ user.address.full_address }}
							</TextBlock>
						</div>
					</div>
					<div class="row">
						<div class="col-4">
							<TextBlock label="BVN"> {{ user.bvn }} ({{ $render.date(user.bvn_verified_at) }}) </TextBlock>
						</div>
						<div class="col-4">
							<TextBlock label="GID"> {{ user.gid }} ({{ $render.date(user.gid_verified_at) }}) </TextBlock>
						</div>
						<div class="col-4">
							<TextBlock label="GID Type">
								{{ gid_title }}
							</TextBlock>
						</div>
					</div>
					<div class="row">
						<div class="col-3">
							<TextBlock label="Email Verified">
								{{ $render.bool(user.isEmailVerified) }}
							</TextBlock>
						</div>
						<div class="col-3">
							<TextBlock label="BVN Verified">
								{{ $render.bool(user.isBVNVerified) }}
							</TextBlock>
						</div>
						<div class="col-3">
							<TextBlock label="GID Verified">
								{{ $render.bool(user.isGIDVerified) }}
							</TextBlock>
						</div>
						<div class="col-3">
							<TextBlock label="Credit Verified">
								{{ $render.bool(user.isEmploymentVerified) }}
							</TextBlock>
						</div>
					</div>
					<div class="row">
						<div class="col-3">
							<TextBlock label="Registered">
								{{ $render.date(user.created_at) }}
							</TextBlock>
						</div>
						<div class="col-3">
							<TextBlock label="Modified">
								{{ $render.date(user.updated_at) }}
							</TextBlock>
						</div>
						<div class="col-3">
							<TextBlock label="Trashed">
								{{ $render.date(user.deleted_at) }}
							</TextBlock>
						</div>
						<div class="col-3">
							<TextBlock label="Suspended">
								{{ $render.date(user.suspended_at) }}
							</TextBlock>
						</div>
					</div>
					<template #footer>
						<div class="text-end w-full">
							<SuspensionButton :suspended_at="user?.suspended_at" @suspension="toggleSuspension" />
						</div>
					</template>
				</Block>
			</div>
		</div>
		<div v-if="user.account" class="row">
			<div class="col">
				<Block type="data">
					<template #header>
						<h3 class="block-title">Bank Account</h3>
					</template>

					<div class="row">
						<div class="col">
							<TextBlock label="Account Number">
								{{ user.account.number }}
							</TextBlock>
						</div>
						<div class="col">
							<TextBlock label="Account Bank">
								{{ user.account.bank }}
							</TextBlock>
						</div>
						<div class="col">
							<TextBlock label="Account Name">
								{{ user.account.name }}
							</TextBlock>
						</div>
					</div>
				</Block>
			</div>
		</div>
		<div v-if="user.employment" class="row">
			<div class="col">
				<Block type="data">
					<template #header>
						<h3 class="block-title">Employment</h3>
					</template>
					<div class="row">
						<div class="col">
							<TextBlock label="Employment Type">
								{{ user.employment.type }}
							</TextBlock>
						</div>
					</div>
					<div class="row">
						<div class="col">
							<TextBlock label="Company Name">
								{{ user.employment.name }}
							</TextBlock>
						</div>
						<div class="col">
							<TextBlock label="Company Phone">
								{{ user.employment.phone }}
							</TextBlock>
						</div>
						<div class="col">
							<TextBlock label="Company Email">
								{{ user.employment.email }}
							</TextBlock>
						</div>
					</div>
					<div class="row">
						<div v-if="user.employment.company" class="col-6">
							<TextBlock label="Company Type">
								{{ user.employment.company }}
							</TextBlock>
						</div>
						<div class="col-6">
							<TextBlock v-if="user.employment.number" label="Company CAC Number">
								{{ user.employment.number }}
							</TextBlock>
						</div>
					</div>
					<div class="row">
						<div class="col">
							<TextBlock label="Company Address">
								{{ user.employment.address?.full_address }}
							</TextBlock>
						</div>
					</div>
					<div class="row">
						<div class="col-6">
							<TextBlock label="Role">
								{{ user.employment.role }}
							</TextBlock>
						</div>
						<div class="col-6">
							<TextBlock label="Annual Salary">
								{{ user.employment.salary.formatted }}
							</TextBlock>
						</div>
					</div>
				</Block>
			</div>
		</div>
		<div v-if="user.card" class="row">
			<div class="col">
				<Block type="data">
					<template #header>
						<h3 class="block-title">Debit Card</h3>
					</template>

					<div class="row">
						<div class="col">
							<TextBlock label="Card Name">
								{{ user.card.name }}
							</TextBlock>
						</div>

						<div class="col">
							<TextBlock label="Card Number">
								{{ user.card.number }}
							</TextBlock>
						</div>

						<div class="col">
							<TextBlock label="Card Expiry">
								{{ user.card.expires_at }}
							</TextBlock>
						</div>
					</div>
				</Block>
			</div>
		</div>
		<div class="row">
			<div v-if="user.kin" class="col">
				<Block type="data">
					<template #header>
						<h3 class="block-title">Next Of Kin</h3>
					</template>
					<div class="row">
						<div class="col">
							<TextBlock label="Name">
								{{ user.kin.name }}
							</TextBlock>
						</div>
					</div>
					<div class="row">
						<div class="col-6">
							<TextBlock label="Email">
								{{ user.kin.email }}
							</TextBlock>
						</div>
						<div class="col-6">
							<TextBlock label="Phone">
								{{ user.kin.phone }}
							</TextBlock>
						</div>
					</div>
					<div class="row">
						<div class="col">
							<TextBlock label="Relationship">
								{{ user.kin.relationship }}
							</TextBlock>
						</div>
					</div>
					<div v-if="user.kin.address" class="row">
						<div class="col">
							<TextBlock label="Address">
								{{ user.kin.address.full_address }}
							</TextBlock>
						</div>
					</div>
				</Block>
			</div>
		</div>
		<div v-if="user.wallet" class="row">
			<div class="col">
				<Block type="data">
					<template #header>
						<h3 class="block-title">Wallet</h3>
					</template>

					<div class="row">
						<div class="col">
							<TextBlock label="Bank">
								{{ user.wallet.bank }}
							</TextBlock>
						</div>
						<div class="col">
							<TextBlock label="Account">
								{{ user.wallet.number }}
							</TextBlock>
						</div>
						<div class="col">
							<TextBlock label="Balance">
								{{ $render.money(user.wallet.balance) }}
							</TextBlock>
						</div>
					</div>
					<div class="row">
						<div class="col">
							<TextBlock label="Sub Account">
								<a :href="`https://app.getanchor.co/sub-account/${user.wallet.aid}`" target="_blank">{{ user.wallet.aid }}</a>
							</TextBlock>
						</div>
						<div class="col">
							<TextBlock label="Customer ID">
								{{ user.wallet.cid }}
							</TextBlock>
						</div>
						<div class="col">
							<TextBlock label="Virtual Account">
								{{ user.wallet.vid }}
							</TextBlock>
						</div>
					</div>
				</Block>
			</div>
		</div>
		<div v-if="!user.isBVNVerified" class="row">
			<div class="col">
				<Block type="data">
					<template #header>
						<h3 class="block-title">BVN Verification</h3>
					</template>
					<Form ref="form" @submit="onSubmitBVN">
						<div class="row">
							<div class="col">
								<InputField id="bvn" v-model="bvn" name="bvn" rules="required|length:11|numeric" label="BVN" placeholder="BVN" />
							</div>
						</div>
						<div class="row">
							<div class="col text-center">
								<Button id="action" type="submit" :label="labels" :loading="loading.bvn" :done="done.bvn" class="btn-lg btn-primary" />
							</div>
						</div>
					</Form>
				</Block>
			</div>
		</div>
		<div v-if="!user.isGIDVerified" class="row">
			<div class="col">
				<Block type="data">
					<template #header>
						<h3 class="block-title">GID Verification</h3>
					</template>
					<Form ref="form" @submit="onSubmitGID">
						<div class="row">
							<div class="col-6">
								<InputField id="gid" v-model="gid" name="gid" rules="required" label="GID" placeholder="GID" />
							</div>
							<div class="col-6">
								<SelectField id="gid_type" v-model="gid_type" name="gid_type" rules="required" label="GID Type" placeholder="GID Type" :options="options">
									<option v-for="gid_type in gid_types" :key="gid_type.value" :value="gid_type.value">
										{{ gid_type.name }}
									</option>
								</SelectField>
							</div>
						</div>
						<div class="row">
							<div class="col text-center">
								<Button id="action" type="submit" :label="labels" :loading="loading.gid" :done="done.gid" class="btn-lg btn-primary" />
							</div>
						</div>
					</Form>
				</Block>
			</div>
		</div>
		<EasyTable :user="user" />
		<VaultTable :user="user" />
		<QualificationTable :user="user" />
		<RNPLTable :user="user" />
		<ZeroTable :user="user" />
	</App>
</template>
<script lang="ts">
import type User from '@/models/User'
import { GIDTitle } from '@/models/User'
import Block from '@/vue/components/Block.vue'
import { ButtonLabel } from '@/vue/components/Button'
import Button from '@/vue/components/Button.vue'
import Card from '@/vue/components/Card.vue'
import Form from '@/vue/components/Form.vue'
import InputField from '@/vue/components/InputField.vue'
import Link from '@/vue/components/Link.vue'
import SelectField from '@/vue/components/SelectField.vue'
import TextBlock from '@/vue/components/TextBlock.vue'
import App from '@/vue/templates/App.vue'
import { AxiosError } from 'axios'
import { Options as Select2Options } from 'select2'
import { Component, Prop, toNative, Vue } from 'vue-facing-decorator'
import EasyTable from './snippets/EasyTable.vue'
import QualificationTable from './snippets/QualificationTable.vue'
import RNPLTable from './snippets/RNPLTable.vue'
import SuspensionButton from './snippets/SuspensionButton.vue'
import VaultTable from './snippets/VaultTable.vue'
import ZeroTable from './snippets/ZeroTable.vue'

@Component({
	components: {
		App,
		Card,
		Block,
		TextBlock,
		InputField,
		Link,
		Form,
		Button,
		SelectField,
		EasyTable,
		VaultTable,
		RNPLTable,
		ZeroTable,
		QualificationTable,
		SuspensionButton,
	},
})
class Single extends Vue {
	loading = {
		bvn: false,
		gid: false,
	}
	done = {
		bvn: false,
		gid: false,
	}

	bvn = ''
	gid = ''
	gid_type = ''

	//credit = null as any

	get gid_title() {
		return GIDTitle(this.user.gid_type)
	}

	get labels(): ButtonLabel {
		return {
			normal: 'Update',
			loading: 'Updating',
			done: 'Updated',
		}
	}

	get options(): Select2Options {
		return {
			placeholder: 'Select GID Type',
			minimumResultsForSearch: Infinity,
			//@ts-ignore
			ajax: null,
		}
	}

	@Prop({ type: Object })
	readonly user!: User

	get gid_types() {
		return [
			{ name: 'National ID', value: 'nin' },
			{ name: 'International Passport', value: 'ipn' },
			{ name: 'Drivers License', value: 'dln' },
			{ name: 'Voters Card', value: 'vin' },
		]
	}

	onSubmitBVN(payload) {
		this.loading.bvn = true
		this.$sweet
			.fire({
				title: 'Are You Sure?',
				text: "You won't be able to revert this!",
				icon: 'warning',

				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'YES!',
			})
			.then((result) => {
				if (result.isConfirmed) {
					this.$http
						.patch(this.route('user.ajax.update.bvn', { user: this.user.slug }), payload)
						.then(() => this.router.reload({ only: ['user'] }))
						.catch((e: AxiosError) => this.$error(e))
						.finally(() => (this.loading.bvn = false))
				} else {
					this.loading.bvn = false
				}
			})
	}

	onSubmitGID(payload) {
		this.loading.gid = true
		this.$sweet
			.fire({
				title: 'Are You Sure?',
				text: "You won't be able to revert this!",
				icon: 'warning',

				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'YES!',
			})
			.then((result) => {
				if (result.isConfirmed) {
					this.$http
						.patch(this.route('user.ajax.update.gid', { user: this.user.slug }), payload)
						.then(() => this.router.reload({ only: ['user'] }))
						.catch((e: AxiosError) => this.$error(e))
						.finally(() => (this.loading.gid = false))
				} else {
					this.loading.gid = false
				}
			})
	}

	toggleSuspension(suspension: boolean) {
		this.$sweet
			.fire({
				title: 'Are You Sure?',
				text: "You won't be able to revert this!",
				icon: 'warning',

				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'YES!',
			})
			.then((result) => {
				if (result.isConfirmed) {
					this.$http
						.patch(this.route('user.ajax.update.suspension', { user: this.user.slug }), { suspension: suspension })
						.then(() => this.router.reload({ only: ['user'] }))
						.catch((e: AxiosError) => this.$error(e))
				} else {
					this.loading.gid = false
				}
			})
	}
}
export default toNative(Single)
</script>
